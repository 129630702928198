@import "../../constants";

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;
  width: 100%;
  background-image: url("../../resources/images/landingBanner.png");
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  h1,
  h2,
  h4 {
    color: white;
    text-align: left;
    font-family: "Poppins";
  }

  h4 {
    font-weight: 400;
    font-size: $text-small;
  }

  .technova-container {
    display: flex;
    height: 100%;
  
    .technova-text {
      float: left;
      margin-right: 15px;
    }
  
    .presents-text {
      display: inline-block;
      align-self: flex-end;
      font-size: $text-medium;
      padding-bottom: 15px;
    }
  }

  .hack-text {
    font-family: "Quicksand";
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }

  .description {
    background-image: linear-gradient(to right, #FC6D83, #7676E8, #86E9DA);
    background-clip: text;
    -webkit-text-fill-color: transparent; 
    background-clip: text;
    -moz-text-fill-color: transparent;
    font-weight: 600;
  }

  .location-container {
    display: flex;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
      
    .icon {
      height: 20px;
      width: 20px;
      margin-right: 7px;
      margin-top: 7px;
    }

    .time-text {
      margin-right: 30px;
    }
  }

  .apply-and-socials-container {
    display: flex;
    gap: 15px;
    max-height: 70px;
  }

  .links-container {
    display: inline-block;
    align-self: flex-start;
    vertical-align: 2px;
  }

  .socials-icon {
    height: 30px;
    width: 30px;
    margin-right: 7px;
  }

  .link-2021 { 
    margin-top: 12px;
    margin-left: 10px;
    text-decoration: none; 
    color: #99ACEB;
    font-weight: 100;
  }
  
  @media only screen and (max-width: 600px) {
    img {
      width: 150px;
      height: 40px;
    }
    h1 {
      font-size: $text-large;
    }
    h2 {
      font-size: $text-medium;
    }
  }

  @media only screen and (min-width: 600px) {
    img {
      width: 175px;
      height: 50px;
    }
  }

  @media only screen and (min-width: 600px) {
    img {
      width: 200px;
      height: 60px;
    }
    h1 {
      font-size: $text-xlarge;
    }
    h2 {
      font-size: $text-medium;
    }
  }

  @media only screen and (min-width: 992px) {
    .container {
      padding: 0px 100px;
    }
    img {
      width: 225px;
      height: 70px;
    }
    h1 {
      font-size: $text-xxlarge;
    }
    h2 {
      font-size: $text-large;
    }
  }
}

@include phone {
  .banner {
    background-image: url("../../resources/images/landingBannerMobile.png");
    background-size: cover;
  }
}
