@import "../../constants";

.about {
  &-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    @include tablet {
      margin-bottom: 30px;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 40%;
    @include phone {
      flex-direction: column;
    }
  }

  &-text {
    padding: 20px, 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: $font-regular;
    font-size: $text-medium;
    p {
      margin-top: 15px;
    }
    @include phone {
      width: 100%;
      p {
        margin-top: 10px;
      }
    }
  }

  &-events {
    margin-top: 30px;
    display: grid;
    text-align: center;
    height: 400px;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto;
    grid-template-areas:
      "prep-image hack-image connect-image"
      "prep-caption hack-caption connect-caption";

    img {
      align-self: flex-end;
      justify-self: center;
      width: 80%;
      margin: 20px 0;
    }

    @include tablet {
      display: none;
    }
  }

  &-caption {
    margin: 20px;
    justify-self: center;
  }
}

.caption {
  display: none;
  margin-top: 0px;
  @include tablet {
    display: block;
    margin: 10px 40px;
  }
}

.hover_box {
  display: none;
  width: 95%;
  height: 100%;
  position: fixed;
  top: 32%;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
  }
  @include tablet {
    display: none !important;
  }
}

@media (max-width: 1280px) {
  .hover_box {
    top: 30%;
  }
}

@media (max-width: 1130px) {
  .hover_box {
    top: 27%;
  }
}

@media (max-width: 1022px) {
  .hover_box {
    top: 23%;
  }
}

@media (max-width: 930px) {
  .hover_box {
    top: 19%;
  }
}

@media (min-width: 229px) {
  .hover_box {
    top: 0%;
  }
}

.animate {
  transform: translateY(20px);
  transition: transform 0.3s linear;
  &:hover {
    transform: translateY(-20px);
    transition: transform 0.3s linear;
    .caption {
      display: block;
    }
    .hover_box {
      display: block;
    }
    .date {
      display: none;
    }
  }
  @include phone {
    transform: none;
    &:hover {
      transform: none;
      .date {
        display: block;
      }
      .hover_box {
        display: none;
      }
    }
  }
  @include tablet {
    transform: none;
    &:hover {
      transform: none;
      .date {
        display: block;
      }
      .hover_box {
        display: none;
      }
    }
  }
}

.about-mobile {
  display: none;
  @include tablet {
    display: block;
    margin-bottom: 50px;
  }
}

.about-slider-container {
  position: relative;
  margin-bottom: 90px;
}

.mobile-image {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

.mobile-text {
  position: absolute;
  top: 70%;
  margin: 10px 40px;

  p {
    margin-bottom: 10px;
  }
}
