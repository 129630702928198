@import "../../constants";

.showcase-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    color: black;
    margin-top: 30px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      margin-block: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #FFFAF3;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      width: 5px;
      background-color: #7676E8;
    }

    // TODO: add supports fro firefox

    @include tablet {
      margin: 0px;
    }
  }
  
.showcase {
  &-img {
    width: 50%;
    margin: 80px 0 20px 0;

    @include tablet {
      margin: 10px 0 5px 0
    }
  }

  &-card {
    background-image: url("../../resources/images/sponsorTicket.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-width: 85%;
    margin: 50px 50px 50px 0;
    position: relative;
    border-radius: 15px;

    @media (max-width: 1300px) {
      min-width: 100%;
    }

    @include tablet {
      min-width: 500px;
    }

  }

  &-passenger {
    position: absolute;
    right: 14%;
    bottom: 10%;
    max-width: 120px;
    text-align: center;
    transform: rotate(-90deg);

    h3 {
      filter: blur(0.5px);
      color: white;
      font-size: 22px;
      
    }

    @include tablet {
      max-width: 80px;
      h3 {
        filter: blur(0.3px);
        font-size: 11px;
      }

    }
  }

  &-content {
    margin-right: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 60%;

    p {
      padding-left: 30px;

      @include tablet {
        font-size: 15px;
      }
    }

    @include tablet {
      overflow-y: scroll;
      height: 300px;
      justify-content: flex-start;

      &:nth-child(1) {
        justify-content: space-between;
      }
    }
  }

  &-button {
    position: absolute;
    bottom: 0;
    border-radius: 40px;
    border: 1px solid #7676e8;
    background-color: transparent;
    color: black;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 20%;

    @include tablet {
      position: relative;
      margin-bottom: 10px;
    }
  }

  &-button-content {
    display: flex;
    padding: 10px 20px;
    color: linear-gradient(to right, #19123C, #7676E8);
    align-items: center;
    p {
      margin-right: 20px;
      font-weight: 100;
      padding: 0;
    }

    @include tablet {
      padding: 5px 10px;

      img {
        width: 23px;
      }

      p {
        font: 13px;
      }
    }
  }

}

.microsoft-passenger {
  right: 15%;
  bottom: 13%;
}

.google-passenger {
  right: 17%;
  bottom: 11%;
}

.cockroach {
  margin: 20px 0 20px 0;
}

.microsoft {
  margin: 100px 0 0 0;

  @include tablet {
    margin: 0;
  }
}

.sps {
  width: 40%;
}

.google {
  width: 40%;
}
  