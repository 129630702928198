@import "../../constants";

.team {
    &-container {
        padding-bottom: 30px;
        h2 {
            margin-top: 30px;
        }
    }

    &-title {
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        text-align: left;
        
        @include large-desktop {
            padding: 0;
        }

        @media (max-width: 1400px) {
            padding: 0 50px;
        }

        @media (max-width: 1350px) {
            padding: 0 75px;
        }

        @media (max-width: 1300px) {
            padding: 0 100px;
        }

        @include tablet {
            padding: 0;
        }

        h1 {
            float: left;
        }
    }

    &-text {
        background: rgba(255, 255, 255, 0.2);
        padding: 10px 15px;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 45px;
        display: inline-flex;
        align-items: center;

        &-default {
            display: block;
            @include phone {
                display: none;
            }
        }
        &-mobile {
            display: none;
            @include phone {
                display: block;
            }
        }
    }

    &-photo {
        
        img {
            border-radius: 50%;
            align-self: center;
            width: 170px;
            height: 170px;
            padding: 10px 0;
            margin: 0 auto;
            
            @include tablet {
                width: auto;
                height: 130px;
            }
            
            @include phone {
                width: auto;
                height: 90px;
            }
        }
    }

    &-linkedin {
        img {
            padding: 10px 0;
            &:hover {
            opacity: 0.7;
            }
        }
    }
}