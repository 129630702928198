@import "../../constants";

@keyframes bounce-up-home {
  0% {
    transform: translateY(50px);
    opacity: 1;
  }
  50% {
    transform: translateY(30px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(50px);
    opacity: 1;
  }
}

@keyframes bounce-up-mobile {
  0% {
    transform: translateY(4px);
    opacity: 1;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(4px);
    opacity: 1;
  }
}

@keyframes bounce-up-fast {
  30% {
    transform: translateY(-20px);
  }
  70% {
    transform: translateY(-10px);
  }
  0%,
  10%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
}

.content {
  display: flex;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 60px;
}

.home-img {
    display: flex;
    align-items: flex-end;

    @include tablet {
        display: none;
    }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

    h1 {
        margin-bottom: 25px;
    }

    @include tablet {
        span {
            display: flex;
        }

        .dot {
            display: none;
        }
    }
}

.heading {
  display: none;

  @include tablet {
    display: block;
    h1 {
      font-size: 48px;
      margin: 10px 0;
    }
  }
}

.textloop {
  @include tablet {
    display: none;
  }
}

.h-block {
  display: flex;

  h2 {
    margin-right: 15px;
  }

  p {
    margin-top: auto;
    line-height: 2;
  }
}

.links {
  display: flex;
  align-items: center;
  margin-top: 20px;

    a:nth-child(2) {
        margin-left: 30px;
    }

    @include tablet {
        text: 5px;
    }
}

.learn-more {
  &-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    &:link {
      text-decoration: none;
      font-weight: 400;
    }
    &:hover {
      animation: bounce-up-fast 1.5s linear infinite;
    }
  }
  &-arrow {
    margin-top: 5px;
    width: 50%;
  }
}

h4 {
  margin-top: 5px;
  @include phone {
    line-height: 24px;
    margin-top: 10px;
  }
  @include small-phone {
    line-height: 21px;
    margin-top: 5px;
  }
}

.landing-slogan {
  font-size: $text-xxlarge;
  font-family: "Quicksand";
  .landing-slogan-bold {
    font-family: "Quicksand Bold";
  }
  @include desktop {
    font-size: 58px;
  }
  @include tablet {
    font-size: $text-xlarge;
  }
  @include phone {
    font-size: 36px;
  }
  @include small-phone {
    font-size: 30px;
  }
}
