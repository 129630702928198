@import "../../constants";

.testimonials {
  &-container {
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  &-title {
    p {
      padding-top: 15px;
    }
  }

  &-wrapper-desktop {
    margin-top: 30px;
    display: grid;
    text-align: center;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto;
    height: 330px;

    img {
      cursor: pointer;
      align-self: flex-end;
      justify-self: center;
      width: 80%;
      margin: 20px 0;
    }
    @include tablet {
      display: none;
    }
  }

  &-text-desktop {
    color: white;
    text-align: center;
    margin-top: 30px;
    height: 200px;

    p {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 400px;
      height: 200px;
    }
    @include tablet {
      display: none;
    }
  }

  &-wrapper-tablet {
    display: none;
    margin-top: 30px;
    display: grid;
    text-align: center;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;

    img {
      display: none;
      cursor: pointer;
      align-self: flex-end;
      justify-self: center;
      width: 80%;
      margin: 20px 0;
    }

    @include tablet {
      display: block;
      img {
        display: block;
        margin: auto;
      }
    }
  }

  &-text-tablet {
    display: none;
    color: white;
    text-align: center;
    p {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    @include tablet {
      display: block;
      margin: 0 20px 10px 20px;
    }
  }

  &-slider {
    display: none;

    @include tablet {
      display: block;
    }
  }
}
