@import "../../constants";

.our-sponsors {
  &-container {
    text-align: left;
    padding-top: 20px;

    @include phone {
      height: auto;
    }

    a {
      &:hover {
        opacity: 0.5;
        transform: translateY(-5px);
        transition: 0.1s linear;
      }
    }

    p {
      font-size: $text-xsmall;
    }
  }

  &-title {
    padding: 50px 0 20px 0;
    @include phone {
      text-align: left;
    }
  }

  &-logos {
    padding: 36px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      width: 90%;
      align-self: center;
      justify-self: center;
      text-align: center;
      img {
        width: 100%;
        text-align: center;
      }
    }
    @include phone {
      padding: 16px;
    }

    padding-top: 20px;
    padding-bottom: 60px;
  }

  &-gold {
    align-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 60%;
    padding-top: 40px;

    a {
      max-width: 550px;
    }


    @include tablet {
      a {
        max-width: 450px;
      }
    }

    @include phone {
      width: 100%;
      a {
        padding-top: 10px;
        max-width: none;
      }
    }
  }

  &-silver {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
    display: flex;
    gap: 30px;

    // google
    a:nth-child(2) img {
      width: 80%;
    }

    a {
      max-width: 350px;
    }

    @include tablet {
      gap: 0px;
    }

    @include phone {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 40px;
      justify-content: space-around;
      gap: 8px;

      a {
        img {
          max-width: 100%;
        }
      }
    }
  }

  &-bronze {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 60px;
    padding: 0 80px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    // faire
    a:nth-child(7) img {
      width: 65%;
    }

    // hrt
    a:nth-child(8) img {
      width: 70%;
    }

    a {
      max-width: 200px;
      flex: 1 0 50%
    }

    @include tablet {
      a {
        min-width: 80px;
        flex: 1 0 25%
      }
    }

    @include phone {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 40px;
      gap: 8px;

      a {
        width: 100%;

        img {
          height: 100%;
        }
      }
    }
  }

  &-startup {
    margin-top: 60px;
    width: 12%;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    a {
      min-width: 140px;
      max-width: 140px;
      flex: 1 0 50%
    }

    @include tablet {
      a {
        min-width: 75px;
        flex: 1 0 50%
      }
    }

    @include phone {
      margin-top: 20px;
      gap: 16px;

      a {
        width: 100%;

        img {
          height: 100%;
        }
      }
    }
  }

  &-partners {
    grid-template-columns: repeat(3, 1fr);
    border-radius: 15px;
    padding: 20px 0;
    width: 100%;
    display: flex;
    /* align-content: center; */
    align-items: center;
    justify-content: space-evenly;

    img {
      max-width: 150px;
    }


    @include tablet {
      img {
        max-width: 100px;
      }
    }

    @include phone {
      width: 100%;
      grid-template-columns: repeat(1, 1fr);
      display: grid;

      a {
        display: flex;
        justify-content:center;
        margin: 30px 0;
      }
    }
  }
}
