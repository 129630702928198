@import "../../constants";

.button {
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-purple1;
    color: $text-light;
    font-size: $text-small;
    font-family: "Poppins";
    font-weight: 400;
    border-radius: 10px;
    border-style: none;
    border: 2px solid $grey-purple1;
    text-decoration: none;
    @include phone {
        font-size: 16px;
      }
    
    &:hover,
    &:focus {
        opacity: 0.7;
        box-shadow: 0 0 11px $grey-purple1;
        transform: translateY(-5px);
        transition: 0.1s linear;
    }
}

.button-2 {
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #7676E8, #86E9DA);
    color: $text-dark;
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 600;
    border-radius: 10px;
    border-style: none;
    text-decoration: none;
    @include phone {
        font-size: 16px;
      }
    
    &:hover,
    &:focus {
        opacity: 0.7;
        box-shadow: 0 0 11px $grey-purple1;
        transform: translateY(-5px);
        transition: 0.1s linear;
    }
}

.button-3 {
    padding: 15px 30px;
    border-radius: 20px;
    border: 2px solid linear-gradient(to right, #7676E8, #86E9DA);
}

