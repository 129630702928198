@import "../../constants";

.faq {
  &-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    margin-top: 40px;
  }
  &-title {
    font-family: $font-title;
    font-size: $text-xlarge;
    margin-bottom: 3rem;
    align-self: flex-start;
    @include tablet {
      margin-bottom: 3rem;
      font-size: $text-large;
    }
  }
  &-questions {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
    @include tablet {
      flex-direction: column;
      grid-template-columns: 100%;
    }
  }
  &-left-col,
  &-right-col {
    > * {
      margin-bottom: 1.5rem;
    }
  }
  &-left-col > :nth-last-child(1),
  &-right-col > :nth-last-child(1) {
    margin-bottom: 0;
  }
  &-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    @include phone {
      width: 300px;
    }
    a {
      font-family: $font-bold;
      font-weight: bold;
      text-decoration: none;
    }
  }
}
