@import "../../constants";

.keynote-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 80px;
    margin-bottom: 50px;
    padding: 32px;
    align-items: center;
    @media (max-width: 1200px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 32px;
    }
    @include tablet {
       display: block;
       justify-content: center;
     }
     border-radius: 25px;
}

.keynote-text {
    display: block;
    text-align: left;
    width: 60%;
    @media (max-width: 1200px) {
        width: 100%;
      }
}

.keynote-title{
    text-align: left;
}

.keynote-name-title {
  display: flex;
  padding: 20px 0;
  gap: 20px;
  align-items: center;

  h3 {
    font-weight: 300;
  }

  h8 {
    margin-top: 5px;
  }
}

.ufo {
  @include phone {
    width: 100%;
  }
}
