// Font

$font-logo: "Lexend";
$font-title: "Quicksand Bold";
$font-bold: "Poppins Bold";
$font-regular: "Poppins";

$text-xxsmall: 10px;
$text-xsmall: 14px;
$text-small: 18px;
$text-medium: 24px;
$text-large: 36px;
$text-xlarge: 64px;
$text-xxlarge: 72px;
$text-logo: 36px;

@mixin fontface($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-family: "#{$name}";
    font-style: $style;
    src: url("./resources/fonts/#{$file}.ttf") format("truetype");
  }
}

@include fontface("Lexend", "Lexend/Lexend-Medium");
@include fontface("Quicksand", "Quicksand/static/Quicksand-Regular");
@include fontface("Quicksand Bold", "Quicksand/static/Quicksand-Bold");
@include fontface("Poppins", "Poppins/Poppins-Regular");
@include fontface("Poppins Bold", "Poppins/Poppins-Medium");

// Color Scheme

$grey-purple1: rgba(255, 255, 255, 0.2);
$grey-purple2: #9494DF;

$accent-pink: #FF6078;
$accent-pink-focus: #c95566;
$light-pink: #ffe4e1;
$accent-purple: #7676e8;
$accent-purple-focus: #6363d3;
$background-light: #fffdfa;
$background-dark: #19123c;
$text-light: #fffdfa;
$text-muted: #c4c4c4;
$text-dark: #19123c;
$grey: #c4c4c4;
$light-grey: #e5e9f2;
$light-blue: #c0cbf2;
$dark-blue: #7d92db;

@mixin lightmode() {
  --text-color: #19123c;
  --background-color: #fffaf3;
  --faq-card-bg-color: #c0cbf2;
  --svg-brightness: 0;
  --header-color: #ffd5cf;
  --footer-color: #ffd5cf;
  --x-icon: url("./resources/images/icons/cross.svg");
  --hamburger-icon: url("./resources/images/icons/hamburger.svg");
  --sponsor-logos: transparent;
  --nav-shadow-color: #ffd5cf;
  --image-shadow-color: #ff6078;
  --mlh-logo: url("https://static.mlh.io/brand-assets/logo/official/mlh-logo-black.svg");
}

@mixin darkmode() {
  --text-color: #fffdfa;
  --background-color: #19123c;
  --faq-card-bg-color: #3d3d75;
  --svg-brightness: 100;
  --header-color: #474163;
  --footer-color: #474163;
  --x-icon: url("./resources/images/icons/cross-light.svg");
  --hamburger-icon: url("./resources/images/icons/hamburger-light.svg");
  --sponsor-logos: #c0cbf2;
  --nav-shadow-color: #19123c;
  --image-shadow-color: #ffa69e;
  --mlh-logo: url("https://static.mlh.io/brand-assets/logo/official/mlh-logo-white.svg");
}

// Breakpoints

$width-xsm-max: 340px;
$width-sm-max: 650px;
$width-md-max: 900px;
$width-mld-max: 1250px;
$width-lg-max: 1435px;

@mixin small-phone {
  @media (max-width: #{$width-xsm-max}) {
    @content;
  }
}
@mixin phone {
  @media (max-width: #{$width-sm-max}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$width-md-max}) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: #{$width-mld-max}) {
    @content;
  }
}
@mixin large-desktop {
  @media (max-width: #{$width-lg-max}) {
    @content;
  }
}
